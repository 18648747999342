<template>
  <header :class="{fixed}">
    <div
      v-if="playDefault"
      class="playDefault"
    >
      <div class="phone-info">
        <span>
          <font-awesome-icon :icon="['fas', 'headphones']" />
        </span>
        <span>
          use fones de ouvido
        </span>
        <span>
          E entre no universo CHBLACKSMITH
        </span>
      </div>
      <span
        class="group"
        @click="playAudio"
      >
        <span class="left">
          Tocar
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="147"
          height="170"
          viewBox="0 0 147 170"
        >
          <g
            id="Polygon_1"
            data-name="Polygon 1"
            transform="translate(147) rotate(90)"
            fill="none"
          >
            <path
              d="M85,0l85,147H0Z"
              stroke="none"
            />
            <path
              class="stroke"
              d="M 85 11.98629760742188 L 10.40023803710938 141 L 159.5997619628906 141 L 85 11.98629760742188 M 85 0 L 170 147 L 0 147 L 85 0 Z"
              stroke="none"
              fill="#fff"
            />
          </g>
        </svg>

      </span>
      <span
        class="group"
        @click="goToPage"
      >
        <span class="right">
          Não tocar
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="172.534"
          height="172.534"
          viewBox="0 0 172.534 172.534"
        >
          <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(-443.233 -454.233)"
          >
            <line
              id="Line_1"
              data-name="Line 1"
              y2="238"
              transform="translate(445.354 456.354) rotate(-45)"
              fill="none"
              stroke="#fff"
              stroke-width="6"
            />
            <line
              id="Line_2"
              data-name="Line 2"
              y2="238"
              transform="translate(613.646 456.354) rotate(45)"
              fill="none"
              stroke="#fff"
              stroke-width="6"
            />
          </g>
        </svg>
      </span>
    </div>

    <div class="menu">
      <router-link
        :to="{name:'Home'}"
        tag="span"
        :class="{logo: true, animated: true, main:true}"
      >
        chblacksmith
      </router-link>

      <span
        v-if="showFullLogo"
        :class="{
          logo: true,
          animated: true,
          center,
          letterSpacing,
          fadeOut,
        }"
      >
        chblacksmith
      </span>

      <ul :class="{animated:true}">
        <router-link
          v-for="(route, index) in routes"
          :key="index"
          exact
          :to="route.path"
          active-class="active"
          tag="li"
        >
          {{ route.name }}
        </router-link>
        <li>
          <a
            class="active"
            href="https://blog.chblacksmith.com.br"
            target="_blank"
            rel="noopener"
          >
            Blog
          </a>
        </li>
      </ul>

      <div class="menuRight">
        <div class="social-icons">
          <a
            href="https://www.instagram.com/c.h.blacksmith/"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://www.tiktok.com/@chblacksmith_?_t=8pKyJUUHefV&_r=1">
            <font-awesome-icon :icon="['fab', 'tiktok']" />
          </a>
          <a href="https://youtube.com/@c.h.blacksmith?si=eaYKo13PI3Ejt3Fb">
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </a>
        </div>

        <audio-player
          :class="{animated:true}"
          :autoplay="autoplay"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mapMutations } from 'vuex';
import AudioPlayer from './AudioPlayer.vue';

export default {
    name: 'MainHeaderVue',
    components: {
        AudioPlayer,
    },
    data() {
        return {
            routes: this.$router.options.routes.filter((route) => !route.configRoute),

            showFullLogo: true,
            bounceInLeft: true,
            bounceOutLeft: false,
            center: true,
            fadeOut: false,
            letterSpacing: false,
            autoplay: false,
            playDefault: true,
            fixed: false,
        };
    },
    created() {
        window.onscroll = () => {
            const y = (document.documentElement && document.documentElement.scrollTop)
              || document.body.scrollTop;
            this.fixed = y >= 300;
        };
    },
    methods: {
        ...mapMutations(['setPlaying']),
        playAudio() {
            const audio = document.getElementsByTagName('audio')[0];
            audio.play();
            this.autoplay = true;
            this.goToAnimation();
            this.setPlaying(true);
        },
        goToAnimation() {
            this.playDefault = false;
            this.letterSpacing = true;
            setTimeout(() => {
                this.letterSpacing = false;
                this.fadeOut = true;
                setTimeout(() => {
                    this.showFullLogo = false;
                }, 1000);
            }, 3000);
        },
        goToPage() {
            this.playDefault = false;
            this.showFullLogo = false;
        },
    },
};
</script>

<style scoped>

/*header.fixed {*/
/*  width: 100vw;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  background-color: var(--black);*/
/*}*/

a {
  text-decoration: none;
}

.playDefault {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--black);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playDefault .group {
  position: relative;
  padding: 0 50px;
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
}

.playDefault .group svg {
  width: 140px !important;
  height: auto;
}

.playDefault .group svg * {
  transition: 0.5s;
}

.playDefault .group:hover svg * {
  stroke: var(--red);
  transition: 0.5s;
}

.playDefault .group:hover svg .stroke {
  fill: var(--red);
}

.playDefault .group:hover span.right,
.playDefault .group:hover span.left {
  opacity: 1;
  color: var(--red);
}

.playDefault .group:hover span.left {
  left: -50%;
}

.playDefault .group:hover span.right {
  right: -80%;
}

.playDefault .group span {
  font-size: 80px;
  position: absolute;
  opacity: 0;
  transition: 0.5s;
}

.playDefault .group span.right {
  right: 0;
}
.playDefault .group span.left {
  left: 0;
}

.social-icons {
  /*margin-top: 10px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: white;
}
.social-icons font-awesome-icon {
  margin: 0 10px;
  color: white;
  cursor: pointer;
}
.menuRight {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.phone-info {
  position: fixed;
  display: flex;
  top: 5vh;
  text-transform: uppercase;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .playDefault .group span {
    font-size: 40px;
    opacity: 1;
    top: 120%;
  }

  .playDefault .group span.left {
    left: unset;
    display: none;
  }

  .playDefault .group span.right {
    right: unset;
    display: none;
  }

  .playDefault {
    padding-top: 100px;
    flex-direction: column;
    gap: 100px;
  }
}
</style>
